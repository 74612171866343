.App {
  text-align: center;
}

/* .App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.CreateAdmin {
  background-color: #2c3e50;
  min-height: 100vh;
  color: white;
}

.CreateAdmin p {
  color: blue;
}

.ShowAdminDetails {
  background-color: #2c3e50;
  min-height: 100vh;
  color: white;
}

.UpdateAdminInfo {
  background-color: #2c3e50;
  min-height: 100vh;
  color: white;
}

.SetDefaults {
  background-color: rgb(1, 13, 49);
  /* background-color: #2c3e50; */
  min-height: 100vh;
  color: gold;
}

.ShowAdminList {
  background-color: #2c3e50;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-width: 100px;
  color: white;
}

.AdminPortal {
  background-color: #2c3e50;
  min-height: 100vh;
  color: white;
}

.list {
  display: grid;
  margin: 20px 0 50px 0;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 2em;
}

.card-container {
  width: 450px;
  height: 560px;
  border: 1px solid rgb(249, 253, 7);
  margin: 0 auto;
  border-radius: 5px;
  /* overflow: hidden; */
  align-items: center;
  justify-content: center;
}

.desc {
  height: 130px;
  padding: 10px;
  text-align: center;
}

.desc h2 {
  font-size: 18px;
  font-weight: bold;
  color: gold;
}

.desc h3 {
  font-weight: 400;
  font-size: 16px;
  color: dodgerblue;
}

.desc h5 {
  font-weight: 400;
  font-size: 14px;
  color: cornflowerblue;
}

.desc h3, h5 {
  padding: 10px 0 10px 0;
}

.desc hr {
  height: 1px;
  background-color: #ccc;
  margin-bottom: 30px;
  margin-top: 20px;
}

.displayed {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

hr {
    background-color: #61dafb;
}

p {
  font-weight: 400;
  font-size: 16px;
  color:blue;
}

h1 {
  font-size: 32px;
  font-weight: bold;
}

/* div {
  background-color: #2c3e50;
} */
